import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer = {
	cinema: {
		address: '',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://apps.apple.com/ru/app/кинокасса-билеты-в-кино/id1493004732',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.kinokassa&hl=ru',
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: null,
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		hasCustomMapMarker: true,
	},
	feedback: true,
};
