import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '255, 255, 255',
		'primary-dark': '217, 217, 223',
		'accent': '150, 28, 94',
		'accent-plus': '255, 255, 255',
	},
});
